import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Map from "../../static/img/svg/map.svg"
import MapKey from "../../static/img/svg/map-key.svg"
import MapNorth from "../../static/img/svg/map-north.svg"
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import { Helmet } from "react-helmet"
import {isMobile} from 'react-device-detect'

export default function Place() {
  return (

    <PageTransition
    transitionStyles={{
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
    }}
  >
      <div className="wrapper">

        <Helmet>
          <meta charSet="utf-8" />
          <title>Place - Governors Point</title>
          <meta name="description" content="Governors point is nestled comfortably between the pristine wilderness of Larrabee state park and the university city of Bellingham and exactly halfway between Vancouver bc and Seattle WA. Larrabee state park was created in 1915 as Washington’s first state park and features miles of hiking trails in addition to two freshwater fishing lakes." />
          <link rel="canonical" href="https://governorspoint.com" />
          <body className={isMobile ? `mobile` : ``} />
        </Helmet>

        <Header menuActive="" />

        <div className="main">

          <div className="container">

            <div className="content-width">
              <div className="page-title">
                <h1>The Weight of Time</h1>
                <div className="caption">By John D'Onofrio and George Mustoe</div>
              </div>
              <div className="entry-content">
                <p>My story begins 70 million years ago on the surface of an ancient sea.</p>
                <p>The Mesozoic Era is winding down and what is now the Chuckanut Coast, with its towering forests and elegantly-sculpted coastline is nowhere to be seen. In fact, there is no land here at all—just the endless waters, ebbing and flowing, drawn by the irresistible pull of the moon.</p>
                <p>The bedrock that would eventually form Western Washington has not yet been deposited. This vast sea fills the horizon, broken only by distant isolated islands, the result of the restless slow-motion clashing of plate tectonics. Except for the ceaseless movement of wind on the water, silence reigns supreme.</p>
                <p>Over the course of the next ten million years, a tremendous river begins to flow west from what is now Idaho, its languid current bringing with it immense deposits of sediment. Slowly, these thick feldspar-rich sediments spread out from the river’s mouth, creating a vast expanse of lowlands, forcing the landmass to expand in a westward direction and extending the coastline further and further out to sea.</p>
                <p>The nascent Pacific Northwest bears little resemblance to the mountainous region that we know today but is rather a vast plain covered in subtropical forests.</p>
                <p>By 50 million years ago, this sediment is more than 10,000 feet thick. Local uplifts create steeper slopes that increase the velocity of the flowing water, resulting in a thousand feet of river gravel being deposited on the newly emerging Governors Point. As the Eocene Era gives way to the later Cenozoic Era, our modern continents are being formed.</p>
                <p>As eons pass, flowing water continues to inexorably write the story of change and the sediments that it transports are compressed into the massive Chuckanut Formation, comprised of siltstones, sandstones and conglomerates that in places is 20,000 feet thick and embedded with seams of coal created from ancient compressed peat and vegetation. These sedimentary layers are slowly tilted and twisted by tectonic forces, forming the backbone of the Chuckanut Coast.</p>
                <p>By now, a walk on Governors Point is possible, although hazardous, due to the hungry crocodiles that roam along the shore. This low-lying coastline is a hot, swampy place and – in addition to the crocodiles – is home to giant flightless birds known as Diatryma, impressive seven foot-tall herbivores. Herds of Coryphodon, a hippopotamus-like animal, leave their footprints in the mud. Dog-sized horses mingle with tapirs and civet-cats. The sky overhead is energized by circling birds, and herons and sandpipers ply the shoreline. The herons that are seen today at Governors Point are largely identical to their ancestral forebearers of 50 million years ago: no adaptation has been required. Small carnivores pursue rodents, which scuttle   among frogs and turtles in the shadows of giant ferns and palm trees. Fossils of these ancient palm trees can still be seen along the Chuckanut Coast today.</p>
                <p>Off-shore, mollusks, barnacles and crustaceans thrive in the salt water and the first whales are sounding in the deep and surfacing for air off the tip of the Point, their exhalations giving voice to the now-bountiful sea.</p>
                <p>When the land mass of Washington begins to rise, about 45 million years ago, the flow of the great river is cut off and the temperatures began to cool, replacing the semitropical rain forests with capacious temperate forests of conifers. For the first time, the winters are cold and snow falls.</p>
                <p>When the North Cascades Range begins to rise, some four million years ago, the peaks trap moisture and a rain shadow is created, resulting in the now-familiar climactic patterns of the Pacific Northwest: arid to the east of the mountains and rainy to the west. The Chuckanut Range forms as a result of the folding of sedimentary rock formations.</p>
                <p>Two million years ago, a great ice age begins and vast continental glaciers begin to cyclically advance and retreat. During periods of maximum glaciation, the Chuckanut Coast is buried beneath 5,000 feet of ice.</p>
                <p>10,000 years ago, as the ice retreated, great alluviums of gravel and sand were left covering the Chuckanut Coast, burying it beneath 30 feet of these deposits. These barren sediments were gradually converted to soil by the chemical/physical process related to rock weathering, allowing flora and fauna to re-establish itself. The introduction of organic matter from the plants and animals further enriched the soil, creating the lush environment that sustains life on Governors Point today.</p>
                <p>It is during this period that humans begin to visit Governors Point. These are the Coast Salish People. Both the Nooksack and Lummi tribes frequent the shores of Chuckanut Bay to dig clams, gather other shellfish and catch fish in the bay itself (traces of an ancient shell midden, located on the east side of the Point above the shores of Pleasant Bay can be still be seen today).</p>
                <p>The name ‘Chuckanut’ is believed to be derived from a Nooksack word, ‘Chúkwenet’, meaning “beach or tide goes way out.”</p>
                <p>The Coast Salish people lived in harmony with the land, utilizing the abundant resources which supplied all of their material needs and venerating their connections to the land and sea with ritual and ceremony, a practice that continues to this day.</p>
                <p>Today Governors Point offers us a front-row seat to observe a rich history of change and metamorphosis over time on a scale that can barely be imagined in our modern fast-paced world. The lavish forests, warm golden bluffs and intricate shoreline illustrate the complex tapestry woven by the powerful forces that have shaped our planet, forces that transcend man’s sense of self-importance.</p>
                <p>The complexity—and delicate balance—of this place offer us a deeper perspective, a richer understanding of our place in the grand design. There are profound lessons to be learned here.</p>
              </div>
            </div>

            <div className="module two-col-imgs text-right">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/place-01.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/place-02.jpg" alt="" /></figure>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-left">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/place-03.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/place-04.jpg" alt="" /></figure>
                </div>
              </div>
            </div>

            <div className="module featured-img">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/place-05.jpg" alt="" /></figure>
            </div>

            <div className="module pull-quote">
              <blockquote>
                <p>The herons that are seen today at Governors Point are largely identical to their ancestral forebearers of 50 million years ago: no adaptation has been required.</p>
              </blockquote>
            </div>

            <div className="module featured-img">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/place-06.jpg" alt="" /></figure>
            </div>

            <div className="module two-col-imgs text-right">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/place-07.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/place-08.jpg" alt="" /></figure>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-left">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/place-09.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/place-10.jpg" alt="" /></figure>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-right">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/place-11.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/place-12.jpg" alt="" /></figure>
                </div>
              </div>
            </div>

            <div className="module featured-img">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/place-13.jpg" alt="" /></figure>
            </div>

            <div className="module two-col-imgs text-left">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/place-14.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/place-15.jpg" alt="" /></figure>
                </div>
              </div>
            </div>

            <div className="module map">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="map-wrap">
                  <div className="ie-map"><Map className="map-area" /></div>
                  <MapNorth className="map-north" />
                </div>
                <MapKey className="map-key" />
              </figure>
            </div>

            <div className="module two-col-imgs text-right">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/place-16.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/place-17.jpg" alt="" /></figure>
                </div>
              </div>
            </div>

            <div className="module two-col-imgs text-left">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/place-18.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/place-19.jpg" alt="" /></figure>
                </div>
              </div>
            </div>

            <div className="module featured-img">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/place-23.jpg" alt="" /></figure>
            </div>

            <div className="module two-col-imgs text-right">
              <div className="flex-grid" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                <div className="col-6">
                  <figure><img src="/img/place-21.jpg" alt="" /></figure>
                </div>
                <div className="col-6">
                  <figure><img src="/img/place-22.jpg" alt="" /></figure>
                </div>
              </div>
            </div>

            <div className="module featured-img">
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease"><img src="/img/place-20.jpg" alt="" /></figure>
            </div>

          </div>
        </div>

        <Footer />

      </div>
    </PageTransition>

  )
}
